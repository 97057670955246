import React, { Fragment } from 'react'
import styled from 'react-emotion'
import Link from '../components/Link'
import { One, Row, Three } from '../components/Grid'
import SEO from '../components/SEO'
import { H2, H4, SEOH1, SmallText } from '../components/Style'
import { CONTRAST_COLOR } from '../constants'
import ClientsContext from '../components/ClientsContext'
import groupBy from 'lodash/groupBy'
import orderBy from 'lodash/orderBy'
import Layout from '../components/Layout'

const breadcrumbs = `{
  "@context": "http://schema.org",
  "@type": "BreadcrumbList",
  "itemListElement": [{
    "@type": "ListItem",
    "position": 1,
    "item": {
      "@id": "https://500tech.com/talks",
      "name": "Clients"
    }
  }]
}`

const byIndustry = clients => groupBy(clients, 'industry')

export default () => (
  <Layout>
    <ClientsContext.Consumer>
      {allClients => (
        <Fragment>
          <SEO
            path="/clients"
            title="Companies that worked with 500Tech"
            description="Non-exhaustive list of startups, small businesses, and enterprises that did a project, consulting, or training with 500Tech"
            ld={breadcrumbs}
          />

          <Row>
            <SEOH1>Our clients</SEOH1>

            <Three>
              We are proud to work with companies of all sizes across various
              industries to help them create better user interfaces by
              developing projects, consulting and training their teams. Over the
              last half decade we helped over 200 companies create high-quality
              software.
            </Three>

            <One>
              <SmallText>
                <H4>Client Reviews</H4>
                <p>
                  Read what some of our clients tell about working with us in
                  their honest reviews conducted by Clutch platform on our{' '}
                  <Link to="/testimonials">testimonials page</Link>
                </p>
              </SmallText>
            </One>
          </Row>

          {orderBy(Object.keys(byIndustry(allClients)), null, 'asc').map(
            industry => (
              <Fragment>
                <H2>{industry}</H2>

                <Row>
                  {orderBy(
                    byIndustry(allClients)[industry],
                    ({ name }) => name.toLowerCase(),
                    'asc'
                  ).map(client => (
                    <One>
                      <ClientName>
                        <Link to={client.website}>{client.name}</Link>
                      </ClientName>

                      <Description>{client.description}</Description>
                    </One>
                  ))}
                </Row>
              </Fragment>
            )
          )}
        </Fragment>
      )}
    </ClientsContext.Consumer>

  </Layout>
)

const Description = styled.div`
  font-size: 1rem;
  line-height: 1.4;

  @media only screen and (min-width: 1023px) {
    margin-bottom: 20px;
  }
`

const ClientName = styled.div`
  color: ${CONTRAST_COLOR};
  width: 92%;
`
